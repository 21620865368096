import { useEffect, useState} from "react";
import {ChevronLeft, ChevronRight, LucideX} from "lucide-react";
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function useKeyboardShortcut({  key, onKeyPressed   }) {
    useEffect(() => {
        function keyDownHandler(e) {
            if (e.key === key) {
                e.preventDefault();
                onKeyPressed();
            }
        }
        document.addEventListener("keydown", keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);
}

export default function ImageScroller({closeViewer, IMAGES, index}) {
    const [currentImage, setCurrentImage] = useState(index)
    useKeyboardShortcut({
        key: "ArrowRight",
        onKeyPressed: () => nextImage(),
    })
    useKeyboardShortcut({
        key: "ArrowLeft",
        onKeyPressed: () => prevImage(),
    })
    useKeyboardShortcut({
        key: "Escape",
        onKeyPressed: () => closeViewer(),
    })
    const nextImage = () => {
        setCurrentImage((prev) => (prev + 1) % IMAGES.length)
    }

    const prevImage = () => {
        setCurrentImage((prev) => (prev - 1 + IMAGES.length) % IMAGES.length)
    }

    return (
        <div className="relative z-[4400] w-full h-full overflow-hidden rounded-3xl ">
            {IMAGES.map((image, index) => (
                <div
                    key={index}
                    className={`flex items-center justify-center absolute top-0 left-0 w-full h-full transition-opacity duration-500 ease-in-out ${
                        index === currentImage ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <LazyLoadImage
                        src={image.org}
                        alt={`Slide ${index + 1}`}
                        className="rounded-3xl max-w-full max-h-full w-auto h-auto"
                    />
                </div>
            ))}
            <span
                className="w-12 h-12 hover:bg-primary-100 transition border border-primary-300 bg-primary-300 cursor-pointer flex items-center rounded-3xl justify-center absolute hover:shadow-xl top-1/2 left-4 transform -translate-y-1/2"
                onClick={prevImage}
            >
                    <ChevronLeft className="h-6 w-6"/>
                    <span className="sr-only">Previous image</span>
                </span>
            <span
                className="w-12 h-12 hover:bg-primary-100 transition border border-primary-300 bg-primary-300 cursor-pointer flex items-center rounded-3xl justify-center absolute hover:shadow-xl  top-1/2 right-4 transform -translate-y-1/2"
                onClick={nextImage}
            >
                    <ChevronRight className="h-6 w-6"/>
                    <span className="sr-only">Next image</span>
                </span>
            <span
                className="w-12 h-12 hover:bg-primary-100 transition border border-primary-300 bg-primary-300 cursor-pointer flex items-center rounded-3xl justify-center absolute hover:shadow-xl  top-12 right-4 transform -translate-y-1/2"
                onClick={closeViewer}
            >
                    <LucideX className="h-6 w-6"/>
                    <span className="sr-only">Close</span>
                </span>
            <div className="overflow-scroll absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                <span className={'text-white'}>{currentImage + 1} of {IMAGES.length}</span>
            </div>
        </div>
    )
}