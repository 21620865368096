import {Link} from "react-router-dom";
import {motion, AnimatePresence} from 'framer-motion';
import ImageScroller from "../components/imageScroller.jsx";
import {useEffect, useRef, useState} from "react";
import ImageScrollerHome from "../components/imageScrollerHome.jsx";
const IMAGES = [
    {org: '/slideShow/ss_01.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_02.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_03.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_04.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_05.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_06.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_07.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_08.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_09.jpg', thumb: '/images/1.jpg'},
    {org: '/slideShow/ss_10.jpg', thumb: '/images/1.jpg'},

]
function Home() {
    const [open, setOpen] = useState(false)
    const [images, setImages] = useState(IMAGES)
    let ref = useRef(null)

    // useEffect(() => {
    //     ref.current = setTimeout(() => {
    //         setImages(IMAGES)
    //     }, 800)
    //     return () => clearTimeout(ref.current)
    // }, []);
    return <div className={'flex flex-col items-center'}>
        <AnimatePresence>
            <motion.div
                className={'w-full items-center justify-center'}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.7}}
            >
                <section
                    className="h-svh flex items-center justify-center max-sm:bg-[url('/images/55.jpg')] md:bg-[url('/assets/hero_d.jpg')] bg-cover bg-center py-16 sm:py-20">
                    <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="flex relative items-center flex-col gap-16">
                            <div className="flex -mt-8 flex-col items-center text-center gap-8 sm:gap-10">
                                <div className="flex max-w-xl items-center flex-col gap-4 sm:gap-6">
                                    <motion.div
                                        initial={{opacity: 0, y: 50}}
                                        animate={{opacity: 1, y: 0}}
                                        transition={{delay: 0.3, duration: 0.6}}
                                    >
                                        <h1 style={{textShadow: ' #000 0 0 1000px'}}
                                            className="text-primary-50 text-4xl w-full font-medium tracking-tight sm:text-5xl lg:text-6xl">
                                            Welcome to <br/>
                                            Portland Arts Retreat
                                        </h1>
                                    </motion.div>

                                    <motion.div
                                        initial={{opacity: 0, y: 50}}
                                        animate={{opacity: 1, y: 0}}
                                        transition={{delay: 0.3, duration: 0.6}}
                                    >
                                        <p style={{textShadow: ' #FFF 2px 2px 60px'}}
                                           className="text-primary-50/90 text-center text-lg sm:text-xl">
                                            An environment where ideas can take root and flourish.
                                        </p>
                                    </motion.div>
                                </div>
                                <motion.div
                                    initial={{opacity: 0, y: 50}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: 0.5, duration: 0.6}}
                                >
                                    <Link
                                        to="/about"
                                        style={{boxShadow: ' #000 0 0 1000px'}}
                                        className=" shadow-primary-100 bg-primary-300 hover:bg-primary-700 hover:text-primary-200  focus-visible:outline-primary-600  inline-flex items-center justify-center rounded-full border border-transparent px-5 py-3 text-base font-medium text-primary-700 transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    >
                                        Learn More
                                    </Link>
                                </motion.div>

                            </div>
                            {/*hero image*/}
                            {/*small*/}
                            {/*<motion.div*/}
                            {/*    className={'w-full items-center justify-center max-sm:flex hidden'}*/}
                            {/*    initial={{opacity: 0}}*/}
                            {/*    animate={{opacity: 1}}*/}
                            {/*    transition={{delay: 0.6, duration: 0.6}}*/}
                            {/*>*/}
                            {/*    <div*/}
                            {/*        className="h-[600px] max-sm:block hidden bg-[url('/images/3.jpg')] bg-center w-full rounded-3xl bg-cover"/>*/}
                            {/*</motion.div>*/}

                            {/*<motion.div*/}
                            {/*    className={'w-full items-center justify-center  max-sm:hidden max-md:flex hidden'}*/}
                            {/*    initial={{opacity: 0}}*/}
                            {/*    animate={{opacity: 1}}*/}
                            {/*    transition={{delay: 0.6, duration: 0.6}}*/}
                            {/*>*/}
                            {/*    <div*/}
                            {/*        className="h-[500px] max-sm:hidden max-md:block hidden bg-center bg-[url('/images/hero.jpg')] w-full rounded-3xl bg-cover"></div>*/}

                            {/*</motion.div>*/}
                            {/*<motion.div*/}
                            {/*    className={'w-full flex items-center justify-center max-sm:hidden max-md:hidden max-lg:flex'}*/}
                            {/*    initial={{opacity: 0}}*/}
                            {/*    animate={{opacity: 1}}*/}
                            {/*    transition={{delay: 0.6, duration: 0.6}}*/}
                            {/*>*/}
                            {/*    <div*/}
                            {/*        className="h-[700px]  lg:max-w-6xl max-sm:hidden max-md:hidden max-lg:block bg-center bg-[url('/images/hero.jpg')] w-full rounded-3xl bg-cover"></div>*/}

                            {/*</motion.div>*/}

                        </div>
                    </div>
                </section>
            </motion.div>

        </AnimatePresence>

            <p className="max-sm:w-11/12 md:w-8/12 text-center font-medium tracking-tight text-2xl m-16 max-sm:mb-0">
                The Portland Arts Retreat is an all-expenses paid self-guided residency program in Portland,
                Oregon for artists of any medium. If you’d like to be selected, we invite you to
                <a href={'/apply#apply'} className={'underline'} > apply here.</a>
            </p>

        <AnimatePresence>
            <motion.div
                className={'w-full items-center justify-center'}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 1}}
            >
                <div className={'w-full h-svh py-24 pt-4 flex justify-center items-center'}>
                    <ImageScrollerHome IMAGES={images} index={0} isHome={true}/>
                </div>
            </motion.div>
        </AnimatePresence>

    </div>


}

export default Home