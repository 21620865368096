export default function Footer() {
    return (

        <footer
            className="bg-primary-500/10 mt-16 py-12 pb-3 lg:mt-20 lg:py-20"
            aria-labelledby="footer-heading"
        >
            <h2 id="footer-heading" className="sr-only">Footer</h2>
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex flex-row justify-between">
                    <div className="flex max-w-sm justify-center flex-col items-start gap-0.5 sm:gap-0.5">
                        <img
                            className="h-9 md:h-16 w-auto"
                            src="/assets/logo.webp"
                        />
                        <p className="text-primary-950/70 mb-0 text-base">
                            Portland Arts Retreat
                        </p>
                        <p className="text-primary-950/70 mt-0  text-sm">© 2024 </p>
                    </div>
                    <img className='h-28' src="/assets/coffee-book.svg" alt=""/>
                </div>
            </div>
        </footer>
    )
}