import {Link} from "react-router-dom";
import {motion, AnimatePresence} from 'framer-motion';
import {useHistory} from "react-router-dom";

const links = [
    {
        ref: '01',
        name: 'About',
        href: '/about',
    },
    {
        ref: '02',
        name: 'Gallery',
        href: '/gallery',
    },
    {
        ref: '03',
        name: 'Contact',
        href: '/contact',
    },
    {
        ref: '04',
        name: 'Apply',
        href: '/apply',
    },
];

import {useState} from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";


const Header = () => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const toggleMenu = () => {
        setOpen(!open);
    };

    return (
        <header>

            <div
                className="mx-auto z-10 flex max-w-2xl items-center justify-between px-4 py-4 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
                {/* Brand logo */}
                <div className="flex">
                    <span
                        onClick={e => {
                            e.preventDefault();
                            open && setOpen(false)
                            history.push(`/`)
                        }}
                        className="cursor-pointer focus-visible:outline-primary-950 rounded-md focus-visible:outline focus-visible:outline-2"
                    >
                        <span className="sr-only">Stone</span>
                        <img
                            className="h-9 lg:h-16 w-auto"
                            src={'/assets/logo.webp'}
                            alt="Stone"
                        />
                    </span>
                </div>

                {/* Actions */}
                <div className="-mr-2 flex items-center space-x-2 sm:space-x-3">
                    {/* Toggle menu */}
                    <button
                        type="button"
                        className="text-primary-950 hover:bg-primary-500/10 ring-primary-950 inline-flex h-14 w-14 items-center justify-center rounded-full transition focus:outline-none focus-visible:ring-2"
                        onClick={toggleMenu}
                        aria-controls="website-menu"
                        aria-expanded={open}
                    >
                        <span className="sr-only">Toggle menu</span>
                        <svg
                            className="h-8 w-8 block"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                        >
                            <path d="M19 8H5V10H19V8ZM19 14H5V16H19V14Z"></path>
                        </svg>
                    </button>
                </div>
            </div>

            {/* Menu content */}
            <AnimatePresence>
                {open && (
                    <motion.div
                        initial={{opacity: 0, height: 0}}
                        animate={{opacity: 1, height: 'auto'}}
                        exit={{opacity: 0, height: 0}}
                        transition={{duration: 0.6}}
                        className="relative"
                    >
                        <motion.div
                            initial={{scaleY: 0}}
                            animate={{scaleY: 1}}
                            transition={{duration: 0.2}}
                            style={{originY: 0}}
                            className="absolute rounded-b-3xl rounded-bl-3xl -z-10 inset-0 bg-primary-800/10"
                        />
                        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 relative z-10">
                            <div className="grid items-center grid-cols-1 gap-8 pb-12 pt-6 lg:pt-12">
                                {/* Navigation */}
                                <nav
                                    className="divide-primary-900/10 flex flex-col gap-1  ">
                                    {links.map((link, index) => (
                                        <motion.div
                                            key={link.ref}
                                            initial={{opacity: 0, y: -40}}
                                            animate={{opacity: 1, y: 0}}
                                            exit={{
                                                opacity: 0, y: -40,
                                                transition: {delay: -index * 0.1, duration: 0.3}
                                            }}
                                            transition={{delay: index * 0.1, duration: 0.4}}
                                        >
                                            <span
                                                onClick={e => {
                                                    e.preventDefault();
                                                    toggleMenu();
                                                    history.push(`${link.href}`)
                                                }}
                                                className="cursor-pointer px-4 rounded-3xl hover:bg-primary-500/10 w-full text-primary-950 group inline-flex py-6 text-3xl font-medium tracking-tight transition focus-visible:outline-none sm:py-8 sm:text-4xl"
                                            >
                                                <div
                                                    className="group-focus-visible:outline-primary-950 dark:group-focus-visible:outline-primary-200 flex flex-1 items-center justify-between rounded-3xl group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2">
                                                    <div className="flex items-center gap-6">
                                                        {/*<span className="text-xs">{link.ref}</span>*/}
                                                        <span className="">
                              {link.name}
                            </span>
                                                    </div>
                                                    <svg
                                                        className="text-primary-600 dark:text-primary-400 h-6 w-6 sm:h-8 sm:w-8"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"/>
                                                    </svg>
                                                </div>
                                            </span>
                                        </motion.div>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </header>
    );
};

export default Header;
