import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import {useState} from "react";
const G_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY
const FORM_KEY = import.meta.env.VITE_FORM
export default function Contact(){
    const [markerLocation, setMarkerLocation] = useState({
        lat: 45.477699279785156,
        lng: -122.65291595458984,
    });
    const [buttonText, setButtonText] = useState("Submit");
    const [failCount,setFailCount] = useState(0)
    const [failText,setFailText] = useState('')

    const onSubmit = async (event) => {
        event.preventDefault();
        setButtonText("Loading...");

        const formData = new FormData(event.target);
        formData.append("access_key", FORM_KEY);

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();
        if (data.success) {
            setButtonText("Thank you!");
            event.target.reset();
        } else {
            if(failCount === 0){
                alert("Something went wrong. Please try again!");
                setFailCount(failCount+1)
            }
            if(failCount===1){
                setFailText("Something went wrong. Please try again! " +
                    "If this issue persists, please email us at sikorasretreat@gmail.com")
            }
            setButtonText("Submit");
        }
    };
    return (
        <>
            <section className="py-16 sm:py-20">
                <div className="mx-auto max-w-2xl px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="flex flex-col gap-4 sm:gap-6">
                        <h1 className="text-4xl font-medium tracking-tight sm:text-5xl lg:text-6xl">Contact</h1>
                    </div>
                </div>
            </section>
            <section className="py-16 sm:py-20">
                <div
                    className="mx-auto max-w-2xl px-4 sm:px-6 grid grid-cols-1 lg:grid-cols-2 gap-6 lg:max-w-7xl lg:px-8">
                    <div className='h-96 grid border-primary-400 border gap-x-6 rounded-3xl overflow-hidden'>
                        <APIProvider apiKey={G_KEY}>
                            <Map
                                style={{borderRadius: "20px"}}
                                defaultZoom={11}
                                defaultCenter={markerLocation}
                                gestureHandling={"greedy"}
                                disableDefaultUI
                            >
                                <Marker position={markerLocation}/>
                            </Map>
                        </APIProvider>
                    </div>
                    <dl className="grid gap-6 grid-cols-1 lg:grid-cols-1">
                        <div className='grid gap-y-6'>
                            <div className="bg-primary-500/10  rounded-3xl px-6 py-8">
                                <dt className="sr-only">Postal address</dt>
                                <dd className="flex h-full flex-col justify-between gap-12">
                                    <svg
                                        className="text-primary-600 dark:text-primary-400 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995L16.9497 15.9497ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
                                        ></path>
                                    </svg>
                                    <div className="text-base font-medium">
                                        <p>6209, SE 13th Ave</p>
                                        <p>Portland, OR 97202</p>
                                    </div>
                                </dd>
                            </div>
                            <div className="bg-primary-500/10 rounded-3xl px-6 py-8">
                                <dt className="sr-only">Email</dt>
                                <dd className="flex h-full flex-col justify-between gap-12">
                                    <svg
                                        className="text-primary-600 dark:text-primary-400 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M7.29117 20.8242L2 22L3.17581 16.7088C2.42544 15.3056 2 13.7025 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.2975 22 8.6944 21.5746 7.29117 20.8242ZM7.58075 18.711L8.23428 19.0605C9.38248 19.6745 10.6655 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 13.3345 4.32549 14.6175 4.93949 15.7657L5.28896 16.4192L4.63416 19.3658L7.58075 18.711Z"
                                        ></path>
                                    </svg>
                                    <p className="text-base font-medium">sikorasretreat@gmail.com</p>
                                </dd>
                            </div>
                        </div>

                    </dl>
                </div>
            </section>
            <section className="py-16 sm:py-20">
                <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="grid gap-12 lg:grid-cols-2 lg:gap-8">
                        <div className="flex flex-col gap-4 sm:gap-6">
                            <h2 className="text-3xl font-medium tracking-tight sm:text-4xl">Tell us your thoughts</h2>
                            <p className="text-primary-950/70 dark:text-primary-200/70 max-w-lg text-lg sm:text-xl">
                                If you&apos;d like to learn more about us or tell us something.
                            </p>
                        </div>
                        <form onSubmit={onSubmit} className="mt-3 flex flex-col gap-y-6">
                            <input type="hidden" name="subject" value="Contact form query"/>
                            <input type="hidden" name="from_name" value="Portland Arts Retreat"/>

                            <div>
                                <label htmlFor="full-name" className="sr-only">Full name</label>
                                <input
                                    type="text"
                                    name="full-name"
                                    id="full-name"
                                    autoComplete="name"
                                    className="hover:ring-primary-600 focus:ring-primary-600 bg-primary-50 ring-primary-900/40  placeholder:text-primary-950/60 block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                    placeholder="Full name"
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="sr-only">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    className="hover:ring-primary-600 focus:ring-primary-600 bg-primary-50 ring-primary-900/40 placeholder:text-primary-950/60 block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                    placeholder="Email"
                                />
                            </div>

                            <div>
                                <label htmlFor="message" className="sr-only">Message</label>
                                <textarea
                                    name="message"
                                    id="message"
                                    rows="3"
                                    className="hover:ring-primary-600 focus:ring-primary-600 ring-primary-900/40  bg-primary-50 placeholder:text-primary-950/60 block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                    placeholder="Message"></textarea>
                            </div>

                            <div>
                                <p className={'text-red-700'}>{failText}</p>
                                <button
                                    className="bg-primary-600 hover:bg-primary-700 focus-visible:outline-primary-600  inline-flex items-center justify-center rounded-full border border-transparent px-5 py-3 text-base font-medium text-white transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    type="submit"
                                >
                                    {buttonText}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>


    )
}