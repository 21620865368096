import FAQ from "../components/faq.jsx";


const team = [
    {
        name: 'Anne Sikora ',
        desc: 'Anne is a 59 year old psychotherapist. Originally from Vancouver Canada and had a 30 year therapy practice in NYC where she raised her two daughters Ashley and Lucy. Anne has been engaged in the writers retreat project from its origins. She is a lover of books and writing, especially after living in NYC. Anne was married to a prominent literary agent for 20 years.',
        image: '/team/anne.webp',
        job: 'Executive Director/ President of the Board of Directors \n',
    },
    {
        name: 'Chris Awalt ',
        image: '/team/chris.jpg',
        desc: 'Chris is an editor and a novelist. He is also an avid photographer and a man of letters. He is Anne’s long time partner and was raised in the same town that Friday Night Lights was written about. He is a jack of all trades and never finds a problem he can’t fix with excellence. He has two daughters, Bella and Olivia, and two step daughters, Ashley and Lucy. ',
        job: 'Co-Director/ Member of the Board of Directors \n ',
    },
    {
        name: 'Seth Fowler',
        desc: 'An experienced Customer and Client Services Representative, Founder and Former Director of FHS LGBTQ Alliance, Personal Assistant by day, Drag Queen by night',
        image: '/team/seth.jpg',
        job: 'Director of Operations / Member of the Board of Directors ',
    },
    {
        name: 'Kaiaka Ardana',
        image: '/team/kai.jpg',
        desc: 'Kai is the house manager at west 13th street. He has pretty much single handily renovated the property entirely, transforming it from an over grown haunted house to its former splendor. He is a native Pacific Islander from the Big Island of Hawai’i and the father of two young kids. He is the oldest child in a warm Hawaiian family line. ',
        job: 'House Manager',
    },
    {
        name: 'Lucy Simonoff',
        desc: 'Hello, My name is Lucy Simonoff and I am a student at the University of Edinburgh majoring in Sociology and Psychology. I have a range of experience freelance audio-editing podcasts, as well as content creating for non-profit organisations. I am also a book lover, and I look forward to discovering new literary voices and providing them with a place to flourish. My committee is responsible for reviewing potential writers’ work and selecting those that are the best fit for our workshop.',
        image: '/team/lucy.jpg',
        job: 'Member of the Board of Directors\n',
    },
    {
        name: 'Ashley',
        desc: 'Hello, My name is Lucy Simonoff and I am a student at the University of Edinburgh majoring in Sociology and Psychology. I have a range of experience freelance audio-editing podcasts, as well as content creating for non-profit organisations. I am also a book lover, and I look forward to discovering new literary voices and providing them with a place to flourish. My committee is responsible for reviewing potential writers’ work and selecting those that are the best fit for our workshop.',
        image: '/team/ashley.jpeg',
        job: 'Member of the Board of Directors\n',
    },
    {
        name: 'Heavenly Hedenberg',
        desc: 'Heavenly is a long time resident of Oregon, where she got her Associates Degree from Mount Hood Community College. She has an unbelievable skill for noticing details in her work ethic and an eye for design. She also loves the outdoors, is an experienced barista, baker and gardener, and is studying to get her Real Estate License.',
        image: '/team/heavenly.jpg',
        job: 'Member of the Board of Directors',
    },
    {
        name: 'River Spencer',
        desc: 'Hello, my name is River K. Spencer and I\'m a student at Portland State University currently studying political science with an emphasis on conflict management. And the American judicial system. I\'m passionate about creative endeavors, and I love to see artist dreams realized with the fruition of their work. I want to be an advocate for those who want to work and are passionate about creative work. My committee oversees the obtaining of grants for the Writers Workshop so that faculty and pupils can best have their needs meet. Thank you.',
        image: '/team/river.jpg',
        job: 'Member of the Board of Directors',
    },

];

function About() {
    return <>
        <section className="py-16 sm:py-20">
            <div className="mx-auto max-w-2xl px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex flex-col gap-4 sm:gap-6">
                    <h1 className="text-4xl font-medium tracking-tight sm:text-5xl lg:text-6xl">About Us</h1>
                    <p className="text-primary-950/70 px-4 mx-auto max-w-2xl text-lg sm:text-xl">
                        The Portland Arts Retreat was founded to support writers and visual artists, familiarize them
                        with the Oregon area, and to provide a sanctuary which encourages the creative process through
                        peace and quiet, clean air, clean water, close proximity to nature, and wildlife. The retreat is
                        located on a historic property and was designed by Portland architect Wade Hampton Pipes In
                        1912.
                    </p>
                </div>
            </div>
        </section>

        <section className="py-8 sm:py-20">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col gap-12 mb-16">
                    <h2 className="text-3xl font-medium tracking-tight text-center sm:text-4xl">Our History</h2>
                    <div
                        className="flex gap-y-8 gap-x-16 md:max-w-screen-md lg:max-w-screen-lg mx-auto flex-col md:flex-row">
                        <div className={'flex flex-col w-full items-center'}>
                            <img
                                 style={{backgroundSize:"100% 100%"}}
                                className="mx-auto max-w-[80%] md:mt-2 h-auto w-auto md:max-w-[100%] rounded-3xl"
                                src="/assets/history.webp"
                                alt=" Richard and Dorothy Sikora."
                            />
                            <p className={'pt-2'}> Richard and Dorothy Sikora.</p>
                        </div>
                        <div className="space-y-6 px-4 text-justify mx-auto w-auto md:max-w-[50%]">
                            <p className="text-primary-900 text-base sm:text-lg">
                                The Portland Arts Retreat was inspired by art lovers Richard and Dorothy Sikora.
                                Richard was an Ethics professor at UBC in the 70’s and 80’s, and Dorothy was a
                                visual artist whose art was featured among notable female artists in Canada. The
                                couple owned a classical record store called Sikora’s Classical Records in
                                Vancouver
                                Canada that was beloved for over forty years. They moved to Portland because of
                                its
                                focus on the environment and its progressive political values.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                The Sikoras’ only daughter, Anne Sikora, had the idea of the arts retreat to
                                support
                                the creative community of Portland. Anne lived in NYC for thirty years where she
                                had
                                a psychotherapy practice. Her children Ashley and Lucy are on the board of
                                directors
                                reviewing potential manuscripts with other writers for the arts retreat. Their
                                father is a literary agent in NYC, so this is a family affair.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-12 ">
                    <h2 className="text-3xl font-medium tracking-tight text-center sm:text-4xl">Mission Statement</h2>
                    <div className="flex gap-y-8 gap-x-16 md:max-w-screen-md lg:max-w-screen-lg mx-auto flex-col md:flex-row-reverse">
                        <div className={'flex flex-col w-full items-center'}>
                            <img
                                className="mx-auto max-w-[80%] md:mt-2 h-auto w-auto md:max-w-[100%] rounded-3xl"
                                src="/assets/mission.webp"
                                alt="Richard and Dorothy Sikora."
                            />
                        </div>
                        <div className="space-y-6 px-4 text-justify mx-auto w-auto md:max-w-[50%]">
                            <p className="text-primary-900 text-base sm:text-lg">
                                The Portland Arts Retreat is meant to foster an environment where ideas can take
                                root and flourish.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                The retreat’s model is inspired by Hawthornden Castle, a well established retreat in
                                the UK. Hawthornden uses an application process to find the most qualified writers
                                who are serious about their craft.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                The idea is that folks are able to dream and imagine free from the pressures and
                                demands of daily life. We request that people put away their cellphones and find
                                other arrangements for childcare so that they can focus their attention on artistic
                                work that might not otherwise be possible.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                As it happens, there is a diaspora of personalities on the board of directors reading and evaluating manuscripts, including a disabled person, a Native American, a Pacific Islander, a transgender woman and many native Portland voices. A rich tapestry.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FAQ/>
        <section className="py-16 sm:py-20">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="sm:space-8 space-y-12">
                    <h2 className="text-3xl font-medium tracking-tight text-center sm:text-4xl">Meet our team</h2>
                    <ul role="list" className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            team.map((item, i) => (
                                <li key={i}
                                    className="border-primary-900/10 rounded-3xl border px-6 py-6">
                                    <div className="space-y-6 flex justify-between flex-col">
                                        <div style={{backgroundImage:`url(${item.image})`}} className={'flex bg-cover justify-between hover:shadow-none transition shadow-xl items-center h-svh max-h-[350px] rounded-2xl w-full'}>
                                        </div>
                                        <div className="text-center">
                                            <h3 className="text-2xl font-medium">{item.name}</h3>
                                            <p className="text-primary-950/70 text-base">{item.job}</p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>

    </>
}

export default About