import './App.css'
import {Switch, Route, Link,} from "react-router-dom";
import Home from "./pages/home.jsx";
import About from "./pages/about.jsx";
import Header from "./components/header.jsx";
import Footer from "./components/footer.jsx";
import Apply from "./pages/apply.jsx";
import Contact from "./pages/contact.jsx";
import Gallery from "./pages/gallery.jsx";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://0dd16f6bc3601ff6f64e2f6f933b8b54@o4508140942327808.ingest.us.sentry.io/4508140944949248",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
    return (
        <div className="App">
            <Header/>
            <Switch>
                <Route exact path='/'><Home/></Route>
                <Route path='/about'><About/></Route>
                <Route path='/gallery'><Gallery/></Route>
                <Route path='/contact'><Contact/></Route>
                <Route path='/apply'><Apply/></Route>
            </Switch>
            <Footer/>
        </div>
    )
}

export default App
